import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslations } from '@veraio/strank';
import { isEmpty, isFunction, omit } from '@veraio/core';
import Papa from 'papaparse';
import { CheckButton, Collapse, Icon, SearchBar, showError, showSuccess, Spinner } from 'components/ui';
import { getUserDisplayName, orderUsersByLetter } from 'utils';
import { getUsersData, searchUsers } from 'services';
import Avatar from '../Avatar';
import { usersSearchContainer } from './styles';
import { hasRole, USER_ROLES } from '@oneecosystem/authenticate';

const UsersSearch = (props) => {
  const { users, label, resetUsersList, filterUsers, multiple, onChangeSelection } = props;
  const { getText } = useTranslations();
  const [addedUsers, setAddedUsers] = useState([]);
  const [loading, setLoading] = useState({ search: false, cache: false });
  const [contactsByLetter, setContactsByLetter] = useState({});
  const [notFound, setNotFound] = useState([]);
  const isSuperAdmin = hasRole(USER_ROLES.CHAT_SUPER_ADMIN);

  useEffect(() => {
    getContactsByLetter();
  }, [addedUsers]);

  useEffect(() => {
    if (resetUsersList) {
      setAddedUsers([]);
      setContactsByLetter({});
    }
  }, [resetUsersList]);

  const getContactsByLetter = async () => {
    const orderedContacts = orderUsersByLetter(addedUsers);
    setContactsByLetter(orderedContacts);
  };

  const handleSearchUsers = async (val) => {
    // Ignore short or empty searches
    if (!val || val.length < 3) return;

    setLoading((prev) => ({ ...prev, search: true }));
    const [res, err] = await searchUsers(val);

    setLoading((prev) => ({ ...prev, search: false }));

    if (err) return showError(err);
    return isFunction(filterUsers) ? filterUsers(res) : res;
  };

  const handleDelete = (options) => {
    options.inputProps.onDelete && options.inputProps.onDelete();
    !multiple && onChangeSelection(null);
  };

  const handleSelect = (isChecked, selectedUserId) => {
    if (!multiple && isChecked) return onChangeSelection(selectedUserId);

    const updatedUsers = isChecked
      ? [...(users ?? []), selectedUserId]
      : (users ?? []).filter((userId) => userId !== selectedUserId) ?? [];

    onChangeSelection(updatedUsers);
  };

  const handleOpenUploadFiles = () => window.addEventListener('focus', () => {});

  const handleAddFiles = (event) => {
    event.preventDefault();

    if (!event.target.files[0]) return;
    setLoading((prev) => ({ ...prev, cache: true }));
    Papa.parse(event.target.files[0], {
      header: true,
      skipEmptyLines: true,
      complete: async (results) => {
        const userIds = results.data.map((user) => user.keycloakId) ?? [];
        const onlyNotAdded = addedUsers.length
          ? userIds.filter((id) => !addedUsers.find((user) => user.id === id))
          : userIds;
        const [res, err] = await getUsersData({ users: onlyNotAdded });

        if (err) {
          showError(err);
          setLoading((prev) => ({ ...prev, cache: false }));
          return;
        }

        if (!res?.length) {
          setNotFound(userIds);
          setLoading((prev) => ({ ...prev, cache: false }));
          showError(getText('noUsersFound'));
          return;
        }

        if (userIds?.length !== res.length) {
          const missing = (userIds ?? []).filter((id) => !res.find((r) => r.id === id)) ?? [];
          missing?.length && setNotFound((prev) => [...prev, ...missing]);
        }

        const filtered = res.filter((r) => !(users ?? []).find((user) => user.id === r.id)) ?? [];
        setAddedUsers((prev) => [...prev, ...filtered]);
        onChangeSelection([...(users ?? []), ...(filtered ?? []).map((f) => f.id)]);
        setLoading((prev) => ({ ...prev, cache: false }));
        filtered?.length && showSuccess(getText('successfullyAddedUsers', filtered.length));
      },
    });
  };

  return (
    <div css={usersSearchContainer}>
      <div className="search-header">
        {label && <label>{label}</label>}
        {isSuperAdmin && (
          <div className="file-input">
            <Spinner size={20} loading={loading.cache} />
            <label htmlFor="files" className="input-label">
              {getText('import')}
              <Icon iconName="group_add" className="action-icon" size={18} onClick={handleOpenUploadFiles} />
            </label>
            <input id="files" type="file" accept=".csv" hidden onChange={handleAddFiles} />
          </div>
        )}
      </div>
      <SearchBar
        className="search-bar"
        placeholder="Search"
        displayKey={'firstName'}
        noDataText={getText('noUsersFound')}
        getOptions={handleSearchUsers}
        loading={loading.search}
        renderSuggestion={(val) => (
          <div
            className="suggestion"
            {...val?.itemProps}
            role={val?.itemProps?.role}
            onClick={() => {
              handleSelect(!users?.includes(val.item.id), val.item.id);

              multiple &&
                !addedUsers.find((user) => user?.id === val.item.id) &&
                setAddedUsers((prev) => [...(prev ?? []), val.item]);

              val.itemProps.onClick && val.itemProps.onClick(val?.item);
            }}>
            <div className="user-info">
              <Avatar pictureUrl={val?.item?.pictureUrl} status={val?.item?.status} width={30} />
              {getUserDisplayName(val?.item)} ({val?.item?.username})
            </div>
            {multiple && users?.includes(val.item.id) && <Icon iconName="check" />}
          </div>
        )}
        renderInput={(val) => (
          <div {...val.containerProps}>
            <label htmlFor={val.inputProps.id}>
              <Icon {...val.iconProps} />
            </label>
            <input {...omit(val.inputProps, ['onDelete'])} />
            <label htmlFor={val.inputProps.id}>
              <Spinner size={20} loading={loading.search} />
            </label>
            <label htmlFor={val.inputProps.id}>
              <Icon material size={20} iconName="close" css={val.iconProps.css} onClick={() => handleDelete(val)} />
            </label>
          </div>
        )}
      />
      {multiple && (
        <div className="users-container">
          <Collapse header={`${getText('selectedUsers')}: ${users?.length ?? 0}`}>
            <div className="users-body">
              {!isEmpty(contactsByLetter) ? (
                Object.keys(contactsByLetter).map((key) => (
                  <div key={`letter-${key}`}>
                    <div className="letter">{key}</div>
                    <ul className="users-list">
                      {contactsByLetter[key].map((contact) => (
                        <li key={`contact-${contact.id}`} className="user">
                          <CheckButton
                            className="check-user"
                            onChange={(value) => handleSelect(value, contact.id)}
                            value={!!(users ?? []).includes(contact.id)}>
                            {getUserDisplayName(contact)}
                          </CheckButton>
                        </li>
                      ))}
                    </ul>
                  </div>
                ))
              ) : (
                <div className="no-data-text">{getText('searchForUsersToAdd')}</div>
              )}
            </div>
          </Collapse>
        </div>
      )}

      {!!notFound?.length && (
        <div className="missing-users">
          <p className="missing-users-title">
            {getText('someUsersNotFound')} ({notFound.length}):
          </p>
          <div className="missing-users-body">
            {notFound.map((id) => (
              <p key={id}>{id}</p>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

UsersSearch.propTypes = {
  users: PropTypes.array,
  label: PropTypes.string,
  resetUsersList: PropTypes.bool,
  multiple: PropTypes.bool,
  filterUsers: PropTypes.func,
  onChangeSelection: PropTypes.func,
};

export default UsersSearch;
