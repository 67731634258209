import { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslations } from '@veraio/strank';
import { Icon } from 'components/ui';
import { ActionsContainer, ConfirmationModal, FileBox, ImageBox, TextWithDotsAnimation } from 'components/shared';
import { displayTime, parseUuid7ToDate, copyToClipboard, getDateTimeLabel } from 'utils';
import { historyModalBody, messageContainer } from './styles';
import { deleteMessage, getMessageVersions } from 'services';
import { setEditModeMessage, useChatStore } from 'stores';
import { hasAtLeastOneRole, USER_ROLES } from '@oneecosystem/authenticate';

const MessageBox = (props) => {
  const { message, isOwn, actionsPosition, onHide } = props;
  const { currentChat } = useChatStore();
  const { getText } = useTranslations();
  const [openContainer, setOpenContainer] = useState({ messageActions: false });
  const [openModal, setOpenModal] = useState({ delete: false, history: false });
  const [actionsOnTop, setActionsOnTop] = useState(false);
  const [versions, setVersions] = useState([]);
  const messageRef = useRef();
  const isAdmin = hasAtLeastOneRole([USER_ROLES.CHAT_ADMIN, USER_ROLES.CHAT_SUPER_ADMIN]);

  const toggleMoreContainer = (e) => {
    e.stopPropagation();
    const position = messageRef.current?.getBoundingClientRect();
    setActionsOnTop(position?.top + 300 >= window.innerHeight);
    setOpenContainer((prev) => ({ messageActions: !prev.messageActions }));
  };

  const handleCopyText = (e) => {
    e.stopPropagation();
    copyToClipboard(message.content, 'Successfully copied text');
  };

  const handleCloseContainer = () => setOpenContainer({});

  const handleDeleteMessage = () => {
    const data = {
      messageId: message.messageId,
      targetId: message.targetId ?? currentChat?.id,
      targetType: message.targetType ?? currentChat?.targetType,
    };
    deleteMessage(data, handleCloseModal);
  };

  const handleCloseModal = () => setOpenModal({ delete: false });

  const showMessageHistory = () => {
    fetchMessageVersions();
    setOpenModal({ history: true });
  };

  const handleStartEditMode = () => {
    setEditModeMessage(message);
    handleCloseContainer();
  };

  const fetchMessageVersions = () => {
    getMessageVersions({ messageId: message.messageId }, (payload) => {
      setVersions(payload);
    });
  };

  return (
    <>
      <div css={messageContainer(isOwn)}>
        <div className="message-body">
          {message.deletedAt && isAdmin && (
            <button onClick={onHide} className="message-tag">
              {getText('deleted')}
            </button>
          )}
          {!message.deletedAt && message.editedAt && (
            isAdmin ? <button onClick={showMessageHistory} className="message-tag">
              {getText('edited')}
            </button>
            : <span className="message-tag">{getText('edited')}</span>
          )}
          {message.typing ? (
            <TextWithDotsAnimation text={getText('typing')} type={isOwn ? 'primary' : 'secondary'} />
          ) : (
            <>
              <div className="message">{message.content}</div>
              {message.images?.map((image) => (
                <ImageBox key={`image-${image.id}`} image={image} isRight={isOwn} />
              ))}
              {message.files?.map((file) => (
                <FileBox key={`file-${file.id}`} file={file} isRight={isOwn} />
              ))}
              <div className="time-container">
                <Icon iconName="schedule" size={13} className="time-icon" />
                <span>{message.messageId && displayTime(parseUuid7ToDate(message.messageId))}</span>
              </div>
            </>
          )}
        </div>
        {!message.typing && (
          <div className="action-buttons" ref={messageRef}>
            <Icon iconName="more_vert" size={18} className="more-icon" onClick={toggleMoreContainer} />
            {openContainer.messageActions && (
              <ActionsContainer
                styles={{
                  top: actionsOnTop ? 'auto' : 25,
                  bottom: actionsOnTop ? 85 : 'auto',
                  right: isOwn && 0,
                  width: 150,
                  ...(actionsPosition ?? {}),
                }}
                onBlur={handleCloseContainer}>
                <div role="button" tabIndex={0} onClick={handleCopyText} className="item">
                  <p className="name">{getText('copy')}</p>
                  <Icon iconName="content_copy" size={16} />
                </div>
                {isOwn && !message.deletedAt && (
                  <>
                    <div role="button" tabIndex={0} onClick={handleStartEditMode} className="item">
                      <p className="name">{getText('edit')}</p>
                      <Icon iconName="edit" size={16} />
                    </div>
                    <div role="button" tabIndex={0} onClick={() => setOpenModal({ delete: true })} className="item">
                      <p className="name">{getText('delete')}</p>
                      <Icon iconName="delete" size={16} />
                    </div>
                  </>
                )}
                {isAdmin && (message.deletedAt || message.editedAt) && (
                  <>
                    <hr />
                    {message.editedAt && (
                      <div role="button" tabIndex={0} onClick={showMessageHistory} className="item">
                        <p className="name">{getText('history')}</p>
                        <Icon iconName="history" size={16} />
                      </div>
                    )}
                    {message.deletedAt && (
                      <div role="button" tabIndex={0} onClick={onHide} className="item">
                        <p className="name">{getText('hide')}</p>
                        <Icon iconName="visibility_off" size={16} />
                      </div>
                    )}
                  </>
                )}
              </ActionsContainer>
            )}
          </div>
        )}
      </div>
      <ConfirmationModal
        showModal={openModal.delete}
        title={getText('deleteMessage')}
        onOk={handleDeleteMessage}
        onCancel={handleCloseModal}>
        {getText('confirmDeleteMessage')}
      </ConfirmationModal>
      <ConfirmationModal
        showModal={openModal.history}
        title={getText('messageHistory')}
        cancelButtonText={getText('close')}
        onCancel={handleCloseModal}>
        <div css={historyModalBody}>
          {[{ createdAt: message.editedAt, content: message.content }, ...versions]?.map((msg, index) => (
            <div key={`version-${index}`} className="history-row">
              <span className="date">{getDateTimeLabel(msg.createdAt)}</span>
              {msg.content?.length < 250 ? (
                <span className="content">{msg.content}</span>
              ) : (
                <div
                  role="presentation"
                  className="content"
                  onClick={() =>
                    setOpenModal((prev) => ({
                      ...prev,
                      message: prev.message?.createdAt !== msg.createdAt ? msg : null,
                    }))
                  }>
                  {!openModal.message || openModal.message?.createdAt !== msg.createdAt ? (
                    <span>{msg.content.slice(0, 250)}...</span>
                  ) : (
                    <span>{msg.content}</span>
                  )}
                </div>
              )}
            </div>
          ))}
        </div>
      </ConfirmationModal>
    </>
  );
};

MessageBox.propTypes = {
  message: PropTypes.object,
  isOwn: PropTypes.bool,
  actionsPosition: PropTypes.object,
  onHide: PropTypes.func,
};

export default MessageBox;
