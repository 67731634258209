import { emitters } from 'enums/chat';
import { sendMessage } from './websocket';

const MESSAGES_DIRECTION = {
  before: 'before',
  after: 'after',
};

// Send message to chat / group / channel
export const sendChatMessage = (data, callback) => sendMessage(emitters.sendMessage, data, callback);

// Search for messages across channels/groups/chats
export const search = (data, callback) => sendMessage(emitters.search, data, callback);

// Add new direct chat when opened for the first time.
export const createDirectChat = (data, callback) => sendMessage(emitters.createChat, data, callback);

// Search for messages across channels/groups/chats
export const getPreviousMessages = (data, callback) =>
  sendMessage(emitters.messageHistory, { ...data, direction: MESSAGES_DIRECTION.before }, callback);

// Get messages after the last we have.
export const getNextMessages = (data, callback) =>
  sendMessage(emitters.messageHistory, { ...data, direction: MESSAGES_DIRECTION.after }, callback);

// Edit own message from chat/group/channel
export const editMessage = (data, callback) => sendMessage(emitters.editMessage, data, callback);

// Delete own message from chat/group/channel
export const deleteMessage = (data, callback) => {
  console.log(data);
  sendMessage(emitters.deleteMessage, data, callback);
};

export const getMessageVersions = (data, callback) => sendMessage(emitters.messageVersions, data, callback);
