import PropTypes from 'prop-types';
import { Button, Icon, Modal } from 'components/ui';
import { confirmationModalContainer } from './styles';

// Should be moved to components/ui!
/// Where to place it? In components/ui/Modal or in separate folder components/ui/ConfirmationModal?
const ConfirmationModal = (props) => {
  const { children, title, showModal, okButtonText, cancelButtonText, onOk, onCancel } = props;

  return (
    <Modal show={showModal} css={confirmationModalContainer} onClose={onCancel}>
      <div className="modal-container">
        <div className="modal-title">
          <h5>{title}</h5>
          <Icon iconName="close" size={16} onClick={onCancel} />
        </div>
        <div className="modal-body">{children}</div>
        <div className="action-container">
          {onCancel && (
            <Button small type="secondary" className="close-button" onClick={onCancel}>
              {cancelButtonText ?? 'Cancel'}
            </Button>
          )}
          {onOk && (
            <Button small onClick={onOk}>
              {okButtonText ?? 'Confirm'}
            </Button>
          )}
        </div>
      </div>
    </Modal>
  );
};

ConfirmationModal.propTypes = {
  children: PropTypes.any,
  title: PropTypes.string,
  showModal: PropTypes.bool,
  okButtonText: PropTypes.string,
  cancelButtonText: PropTypes.string,
  onOk: PropTypes.func,
  onCancel: PropTypes.func,
};

export default ConfirmationModal;
