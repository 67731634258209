import { useEffect, useState } from 'react';
import { useTranslations } from '@veraio/strank';
import { ChannelFormModal, Icon, SearchInput, showSuccess, ConversationListItem } from 'components';
import { setCurrentChat, useChatStore } from 'stores';
import { chatTypes } from 'enums';
import { createChannel } from 'services';
import { resetUnreadMessages, useMessagesStore } from 'stores/messages';
import { channelsContainer } from './styles';
import { hasAtLeastOneRole, USER_ROLES } from '@oneecosystem/authenticate';
import { getTimestampFromUuidv7 } from 'utils';

const Channels = () => {
  const { getText } = useTranslations();
  const { channels, otherChannels, currentChat } = useChatStore();
  const { messages } = useMessagesStore();
  const [allChannels, setAllChannels] = useState({
    myChannels: [],
    otherPublicChannels: [],
  });
  const [openChannelModal, setOpenChannelModal] = useState(false);
  const [openedChatId, setOpenedChatId] = useState(currentChat?.id);
  const [searchText, setSearchText] = useState();
  const isAdmin = hasAtLeastOneRole([USER_ROLES.CHAT_SUPER_ADMIN, USER_ROLES.CHAT_ADMIN]);

  useEffect(() => {
    getMyChannels();
  }, [channels, otherChannels]);

  useEffect(() => {
    setAllChannels((prev) => ({
      ...prev,
      myChannels: sortChannelsByLastMessage(prev.myChannels),
    }));
  }, [messages]);

  useEffect(() => {
    setOpenedChatId(currentChat?.id);
  }, [currentChat?.id]);

  const getMyChannels = (options) => {
    const search = options?.search ?? searchText;
    const filteredChannels = filterChannelsByText(search);
    const sortedChannels = {
      myChannels: sortChannelsByLastMessage(filteredChannels.myChannels),
      otherPublicChannels: filteredChannels.otherPublicChannels,
    };

    setAllChannels(sortedChannels);
  };
  const filterChannelsByText = (text) => {
    return text
      ? {
          myChannels: channels.filter((channel) => channel.name.toLowerCase().includes(text.toLowerCase())),
          otherPublicChannels: otherChannels.filter((channel) =>
            channel.name.toLowerCase().includes(text.toLowerCase()),
          ),
        }
      : {
          myChannels: channels,
          otherPublicChannels: otherChannels,
        };
  };

  const sortChannelsByLastMessage = (channelsArray) => {
    return channelsArray.sort((channelA, channelB) => {
      // Not every channel has messages
      if (!(messages[channelA.id]?.messages ?? [])[0]) return 1;
      if (!(messages[channelB.id]?.messages ?? [])[0]) return -1;

      const channelALastMessage = messages[channelA.id].messages[0] ?? {};
      const channelBLastMessage = messages[channelB.id].messages[0] ?? {};

      const timeA = channelALastMessage.messageId && getTimestampFromUuidv7(channelALastMessage.messageId);
      const timeB = channelBLastMessage.messageId && getTimestampFromUuidv7(channelBLastMessage.messageId);

      return timeB - timeA;
    });
  };

  const handleSearch = (val) => {
    setSearchText(val);
    getMyChannels({ search: val });
  };

  const handleOpenChat = (id) => {
    setCurrentChat(id, chatTypes.channel);
    resetUnreadMessages(id);
  };

  const handleOpenChannelModal = () => setOpenChannelModal(true);

  const handleCloseChannelModal = () => setOpenChannelModal(false);

  const handleCreateChannel = (data, resetForm) => {
    createChannel(data, (response) => {
      resetForm(!response.success);

      showSuccess('Successfully created a channel');
      handleCloseChannelModal();
    });
  };

  return (
    <>
      <div css={channelsContainer}>
        <div className="page-title">
          <h4>{getText('channels')}</h4>
          {isAdmin && <Icon iconName="group_add" color="secondary" onClick={handleOpenChannelModal} />}
        </div>

        <SearchInput placeholder={getText('searchChannels')} onChange={handleSearch} />

        <div className="channels-list">
          {allChannels.myChannels.length || allChannels.otherPublicChannels.length ? (
            <ul>
              {allChannels.myChannels?.map((channel) => (
                <ConversationListItem
                  key={channel.id}
                  chat={channel}
                  isSelected={openedChatId === channel.id}
                  onChatOpen={handleOpenChat}
                />
              ))}
              {allChannels.otherPublicChannels?.map((channel) => (
                <ConversationListItem
                  key={channel.id}
                  chat={channel}
                  isMember={false}
                  isSelected={openedChatId === channel.id}
                  onChatOpen={handleOpenChat}
                />
              ))}
            </ul>
          ) : (
            <div className="no-data">{getText('noChannelsFound')}</div>
          )}
        </div>
      </div>

      {isAdmin && (
        <ChannelFormModal
          showModal={openChannelModal}
          onSubmit={handleCreateChannel}
          onClose={handleCloseChannelModal}
        />
      )}
    </>
  );
};

export default Channels;
