import { getRoles, USER_ROLES } from '@oneecosystem/authenticate';
import { uuidv7 } from 'uuidv7';
import { listeners } from 'enums';
import {
  addChannel,
  addChat,
  addGroup,
  addMessage,
  addUsersToChannelStore,
  addUsersToGroupStore,
  catchupMessages,
  editChannel,
  getChannelById,
  getUserId,
  updateAllChats,
  movePublicChannel,
  removeChannel,
  removeGroup,
  removeUsersFromChannelStore,
  removeUsersFromGroupStore,
  updateUserPresence,
} from 'stores';
import { deleteAllChatMessages, deleteMessage, updateMessage } from 'stores/messages';

export const notifyStore = (messageId, payload) => {
  console.log(`Received "${messageId}" message`, payload);
  const userId = getUserId();
  const userRoles = getRoles();

  switch (messageId) {
    // First initialization of all chats (1:1), groups and channels.
    case listeners.init: {
      updateAllChats(payload);
      break;
    }
    // When new messages have been received while the current user was offline.
    // It needs firebase configuration with generating FCM token to get this message.
    case listeners.catchup: {
      catchupMessages(payload);
      break;
    }
    case listeners.channelCreated: {
      addChannel(payload);
      break;
    }
    case listeners.channelUpdated: {
      editChannel(payload);
      break;
    }
    case listeners.channelDeleted: {
      removeChannel(payload.id);
      deleteAllChatMessages(payload.id);
      break;
    }
    case listeners.channelJoined: {
      // Only public channels can be joined
      if (userId === payload.user.id) movePublicChannel(payload, true);
      else addUsersToChannelStore({ id: payload.id, users: [payload.user] });

      break;
    }
    case listeners.channelLeft: {
      if (userId === payload.user.id) {
        const channelData = getChannelById(payload.id);
        !channelData?.public ? removeChannel(payload.id) : movePublicChannel(payload, false);
        deleteAllChatMessages(payload.id);
      } else removeUsersFromChannelStore({ id: payload.id, users: [payload.user] });
      break;
    }
    // When other users were added to a channel, where the current user is a member
    case listeners.usersAddedToChannel: {
      addUsersToChannelStore(payload);
      break;
    }
    // When the current user was added to a channel. Payload includes channel data.
    case listeners.userAddedToChannel: {
      // const channelData = getChannelById(payload.id);
      !payload.public ? addChannel(payload) : movePublicChannel(payload, true);
      break;
    }
    // When users were removed from a channel. Current user could be in the removed users
    case listeners.usersRemovedFromChannel: {
      // Removing user means BAN. If current user is removed, the channel should be removed from the list
      if (payload.users.find((user) => user.id === userId)) {
        removeChannel(payload.id);
        deleteAllChatMessages(payload.id);
      } else removeUsersFromChannelStore(payload);
      break;
    }
    // When other users were added to a group, where the current user is a member
    case listeners.usersAddedToGroup: {
      addUsersToGroupStore(payload);
      break;
    }
    // When the current user was added to a group. Payload includes group data
    case listeners.userAddedToGroup: {
      addGroup(payload);
      break;
    }
    // When users were removed from a group. Current user could be in the removed users
    case listeners.usersRemovedFromGroup: {
      if (payload.users.find((user) => user.id === userId)) {
        removeGroup(payload.id);
        deleteAllChatMessages(payload.id);
      } else removeUsersFromGroupStore(payload);

      break;
    }
    case listeners.groupCreated: {
      addGroup(payload);
      break;
    }
    case listeners.groupDeleted: {
      removeGroup(payload.id);
      break;
    }
    case listeners.groupLeft: {
      if (userId === payload.user.id) removeGroup(payload.id);
      else removeUsersFromGroupStore({ id: payload.id, users: [payload.user] });
      break;
    }
    // When direct chat was opened for the first time
    case listeners.chatCreated: {
      addChat(payload);
      break;
    }
    // When a message was sent to a chat (1:1), group or channel
    case listeners.messageSent: {
      addMessage(payload);
      break;
    }
    case listeners.messageEdited: {
      updateMessage({ ...payload, editedAt: uuidv7() });
      break;
    }
    case listeners.messageDeleted: {
      const isAdmin = !!userRoles.find(
        (role) => role === USER_ROLES.CHAT_ADMIN || role === USER_ROLES.CHAT_SUPER_ADMIN,
      );
      isAdmin ? updateMessage({ ...payload, deletedAt: uuidv7() }) : deleteMessage(payload);
      break;
    }
    case listeners.presenceUpdated: {
      if (userId === payload.user.id) updateUserPresence(payload.status);
      else console.log('Wait for BE: users data');
      break;
    }
  }
};
