import { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslations } from '@veraio/strank';
import { Avatar, Button, CheckButton, FileInput, Form, Icon, Input, Modal, UsersSearch } from 'components';
import { createChannelContainer } from './styles';
import { omit } from '@veraio/core';
import { getFirstSymbol } from 'utils';
import { getUserId } from 'stores';

const ChannelFormModal = (props) => {
  const { data, showModal, onClose, onSubmit } = props;
  const { getText } = useTranslations();
  const userId = getUserId();
  const [channel, setChannel] = useState({ public: true });
  const [resetUsersList, setResetUsersList] = useState(false);
  const isEdit = !!data;

  const handleCreateChannel = (val, resetForm) => {
    const newChannel = { ...omit(val, ['icon']) };
    if (val?.icon) newChannel.icon = val.icon.data;

    onSubmit(newChannel, (isError) => {
      resetForm(isError);
      !isError && setResetUsersList(true);
    });
  };

  // Check for name - if not changed, don't send it in the body
  const handleUpdateChannel = (val, resetForm) => {
    const omitName = data.name === val.name;
    const newChannel = { ...omit(val, ['icon', omitName && 'name']) };
    if (val?.icon) newChannel.icon = val.icon.data;

    onSubmit(newChannel, (isError) => {
      resetForm(isError);
      !isError && setResetUsersList(true);
    });
  };

  const handleClose = () => {
    onClose();
    setChannel({ public: true });
  };

  const handleFilterUsers = useCallback((users) => users.filter((user) => user?.id !== userId));

  return (
    <Modal show={showModal} onClose={onClose} css={createChannelContainer}>
      <Form
        className="channel-modal-container"
        values={isEdit ? { ...data, ...channel } : channel}
        onSubmit={isEdit ? handleUpdateChannel : handleCreateChannel}>
        <div className="modal-title">
          <h4>{isEdit ? getText('editChannel') : getText('createChannel')}</h4>
          <Icon iconName="close" size={16} onClick={handleClose} />
        </div>

        <div className="modal-body">
          {({ values, setValues }) => {
            const { icon, name } = values;

            return (
              <div className="channel-image-selector">
                <FileInput
                  small
                  noPreview
                  className="upload-button"
                  formId="icon"
                  id="icon"
                  type="file"
                  accept="image/*"
                  label={getText('image')}
                  buttonText={getText('image')}
                />
                <div className="image-preview">
                  {icon ? (
                    <>
                      <Avatar picThumbnailUrl={icon?.data} className="image-container" />
                      <Icon
                        iconName="delete"
                        className="remove-button"
                        size={18}
                        onClick={() => setValues((prev) => ({ ...prev, icon: null }))}
                      />
                    </>
                  ) : (
                    <div className="letter-avatar">
                      <div>{name ? getFirstSymbol(name) : 'C'}</div>
                    </div>
                  )}
                </div>
              </div>
            );
          }}

          <Input
            required
            formId="name"
            className="modal-input"
            placeholder={getText('enterName')}
            label={getText('channelName')}
          />

          {!isEdit &&
            (({ values, setValues }) => (
              <div className="members-container">
                <UsersSearch
                  label={getText('channelMembers')}
                  multiple
                  users={values.users}
                  resetList={resetUsersList}
                  filterUsers={handleFilterUsers}
                  onChangeSelection={(selected) => setValues((prev) => ({ ...prev, users: selected }))}
                />
              </div>
            ))}
          <Input
            formId="description"
            type="textarea"
            placeholder={getText('enterDescription')}
            label={getText('description')}
          />

          {({ values, setValues }) => (
            <>
              <div className="checkbox-container">
                <CheckButton
                  onChange={(value) => setValues((prev) => ({ ...prev, public: value }))}
                  value={values.public}
                  checkBgColor="theme-color"
                  checkBorderColor="gray-700">
                  {getText('publicChannel')}
                </CheckButton>
              </div>
              <div className="checkbox-container">
                <CheckButton
                  onChange={(value) => setValues((prev) => ({ ...prev, readOnly: value }))}
                  value={values.readOnly}
                  checkBgColor="theme-color"
                  checkBorderColor="gray-700">
                  {getText('readOnlyChannel')}
                </CheckButton>
              </div>
            </>
          )}
        </div>

        <div className="action-container">
          {({ onSubmit: onFormSubmit }) => (
            <>
              <Button small type="secondary" className="close-button" onClick={handleClose}>
                {getText('close')}
              </Button>
              <Button small onClick={onFormSubmit}>
                {getText('submit')}
              </Button>
            </>
          )}
        </div>
      </Form>
    </Modal>
  );
};

ChannelFormModal.propTypes = {
  data: PropTypes.object,
  showModal: PropTypes.bool,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func,
};

export default ChannelFormModal;
