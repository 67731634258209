import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import EmojiPicker from 'emoji-picker-react';
import { useTranslations } from '@veraio/strank';
import { isEmpty, pick } from '@veraio/core';
import { setEditModeMessage, useChatStore, useMessagesStore } from 'stores';
import { Button, Icon, Input, useTheme } from 'components/ui';
import { FileBox, ImageBox, ItemsSlider } from 'components/shared';
import { sendMessageContainer } from './styles';
import { editMessage } from 'services';

// Should be removed when file upload is ready
const withFileUpload = false;

const SendMessage = (props) => {
  const { onSend } = props;
  const { getText } = useTranslations();
  const { theme } = useTheme();
  const { currentChat } = useChatStore();
  const { editModeMessage } = useMessagesStore();
  const inputRef = useRef();
  const [openContainer, setOpenContainer] = useState({ emojiSelector: false });
  const [messageData, setMessageData] = useState({});
  const [uploadedFiles, setUploadedFiles] = useState([]);

  useEffect(() => {
    setMessageData(editModeMessage ? { text: editModeMessage.content } : {});
    setUploadedFiles([]);
    setOpenContainer({ emojiSelector: false });
  }, [currentChat?.id]);

  useEffect(() => {
    setMessageData(editModeMessage ? { text: editModeMessage.content } : {});
  }, [editModeMessage]);

  const handleChangeText = (value) => {
    openContainer?.emojiSelector && setOpenContainer({ emojiSelector: false });
    setMessageData((prev) => ({ ...prev, text: value }));
  };

  const toggleEmojiSelector = () => {
    inputRef.current.focus();
    setOpenContainer((prev) => ({ emojiSelector: !prev.emojiSelector }));
  };

  const handleOpenUploadFiles = () => window.addEventListener('focus', () => {});

  const handleAddImages = (event) => {
    event.preventDefault();
    const images = event.target.files;
    const imagesArray = Object.values(images).map((image) => {
      image.pictureUrl = URL.createObjectURL(image);
      image.fileType = 'image';
      return image;
    });

    setMessageData((prev) => ({ ...prev, images: [...(prev.images || []), ...imagesArray] }));
    setUploadedFiles((prev) => [...prev, ...imagesArray]);
  };

  const handleAddFiles = (event) => {
    event.preventDefault();
    const files = event.target.files;

    const filesArray = Object.values(files).map((file) => {
      file.sourceUrl = URL.createObjectURL(file);
      file.fileType = 'file';

      return file;
    });
    setMessageData((prev) => ({ ...prev, files: [...(prev.files || []), ...filesArray] }));
    setUploadedFiles((prev) => [...prev, ...filesArray]);
  };

  const handleSendMessage = () => {
    if (isEmpty(messageData)) return;

    onSend(messageData);
    setMessageData({});
    setUploadedFiles([]);
    setOpenContainer({ emojiSelector: false });
  };

  const handlePickEmoji = ({ emoji }) => {
    inputRef.current.focus();

    const start = (messageData.text ?? '').substring(0, inputRef.current.selectionStart);
    const end = (messageData.text ?? '').substring(inputRef.current.selectionEnd);
    const text = start + emoji + end;

    setMessageData((prev) => ({ ...prev, text: text }));
    inputRef.current.setSelectionRange(start.length + emoji.length, start.length + emoji.length);
  };

  const handleRemoveUploadedFile = (file) => {
    const updatedFiles = uploadedFiles.filter((uFile) => uFile.sourceUrl !== file.sourceUrl);

    setUploadedFiles(updatedFiles);
    setMessageData((prev) => ({
      ...(prev ?? {}),
      files: [...(prev.files ?? []).filter((uFile) => uFile.sourceUrl !== file.sourceUrl)],
    }));
  };

  const handleRemoveUploadedImage = (image) => {
    const updatedFiles = uploadedFiles.filter((uImage) => uImage.pictureUrl !== image.pictureUrl);
    setUploadedFiles(updatedFiles);
    setMessageData((prev) => ({
      ...(prev ?? {}),
      images: [...(prev.images ?? []).filter((uImage) => uImage.pictureUrl !== image.pictureUrl)],
    }));
  };

  const pasteIntoInput = (el, text) => {
    el.focus();
    const val = el.value;
    const selStart = el.selectionStart;
    setMessageData(val.slice(0, selStart) + text + val.slice(el.selectionEnd));
    el.selectionEnd = el.selectionStart = selStart + text.length;
  };

  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      if (event.shiftKey) pasteIntoInput(inputRef.current, '\n');
      else {
        event.preventDefault();
        editModeMessage ? handleEditMessage() : handleSendMessage();
      }
    }
  };

  const handleEditMessage = () => {
    const editedMessage = {
      ...pick(editModeMessage, ['targetId', 'targetType', 'messageId']),
      content: messageData.text,
    };
    editMessage(editedMessage);
  };

  return (
    <div css={sendMessageContainer(!!messageData.images?.length || !!messageData.files?.length, !!editModeMessage)}>
      {editModeMessage && (
        <div className="edit-mode-title">
          <span>{getText('editMessage')}</span>
          <Icon iconName="close" size={18} onClick={() => setEditModeMessage(null)} />
        </div>
      )}
      <div className="message-container">
        <Input
          type="textarea"
          formId="text"
          value={messageData.text}
          inputRef={inputRef}
          className="message-input"
          placeholder={getText('enterMessage')}
          onChange={handleChangeText}
          onKeyDown={handleKeyDown}
        />

        <div>
          <EmojiPicker
            className="emoji-list"
            open={openContainer.emojiSelector}
            autoFocusSearch={false}
            onEmojiClick={handlePickEmoji}
            previewConfig={{ showPreview: false }}
            theme={theme}
          />
          <Icon iconName="mood" className="action-icon" size={18} onClick={toggleEmojiSelector} />
        </div>

        {withFileUpload && !editModeMessage && (
          <>
            <div>
              <label htmlFor="files">
                <Icon iconName="attach_file" className="action-icon" size={18} onClick={handleOpenUploadFiles} />
              </label>
              <input id="files" type="file" hidden onChange={handleAddFiles} />
            </div>

            <div>
              <label htmlFor="images">
                <Icon iconName="imagesmode" className="action-icon" size={18} onClick={handleOpenUploadFiles} />
              </label>
              <input id="images" type="file" hidden onChange={handleAddImages} />
            </div>
          </>
        )}
        <Button
          small
          type="primary"
          className="send-button"
          disabled={(editModeMessage && editModeMessage.content === messageData.text) || !messageData?.text }
          onClick={editModeMessage ? handleEditMessage : handleSendMessage}>
          <Icon iconName={editModeMessage ? 'check' : 'send'} size={20} fill={1} className="send-icon" />
        </Button>
      </div>
      {/* </Form> */}
      {withFileUpload && !!uploadedFiles.length && !editModeMessage && (
        <div className="files-container">
          <ItemsSlider>
            {uploadedFiles?.map((file, index) =>
              file.fileType === 'image' ? (
                <div key={`image-${index}-${file.name}`} className="uploaded-file image-file">
                  <ImageBox small noActions image={file} className="image-container" />
                  <Icon
                    iconName="close"
                    size={14}
                    className="remove-icon"
                    onClick={() => handleRemoveUploadedImage(file)}
                  />
                </div>
              ) : (
                <div key={`file-${index}-${file.name}`} className="uploaded-file">
                  <FileBox small noActions file={file} />
                  <Icon
                    iconName="close"
                    size={14}
                    className="remove-icon"
                    onClick={() => handleRemoveUploadedFile(file)}
                  />
                </div>
              ),
            )}
          </ItemsSlider>
        </div>
      )}
    </div>
  );
};

SendMessage.propTypes = {
  onSend: PropTypes.func,
};

export default SendMessage;
