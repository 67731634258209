import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslations } from '@veraio/strank';
import { Button, Form, Icon, Modal, UsersSearch } from 'components';
import { addMembersModal } from './styles';
import { getUserId, useChatStore } from 'stores';
import { useState } from 'react';

const AddMembersModal = (props) => {
  const { showModal, onClose, onAdd } = props;
  const { currentChat } = useChatStore();
  const userId = getUserId();
  const { getText } = useTranslations();
  const [resetUsersList, setResetUsersList] = useState(false);

  const handleSubmit = (formData, resetForm) => {
    if (!formData.users?.length) return;
    onAdd(formData.users, (isError) => {
      resetForm(isError);
      !isError && setResetUsersList(true);
    });
  };

  const handleClose = () => onClose();

  const handleFilterUsers = useCallback((users) =>
    users.filter((user) => user?.id !== userId && !currentChat?.users?.find((u) => u.id === user.id)),
  );

  return (
    <Modal show={showModal} onClose={onClose} css={addMembersModal}>
      <Form className="members-modal-container" values={currentChat?.users} onSubmit={handleSubmit}>
        <div className="modal-title">
          <h4>{getText('addMembers')}</h4>
          <Icon iconName="close" size={16} onClick={handleClose} />
        </div>

        <div className="modal-body">
          {({ values, setValues }) => (
            <UsersSearch
            label={getText('searchForUser')}
              multiple
              users={values.users}
              resetList={resetUsersList}
              filterUsers={handleFilterUsers}
              onChangeSelection={(selected) => setValues((prev) => ({ ...prev, users: selected }))}
            />
          )}
        </div>

        <div className="action-container">
          {({ onSubmit }) => (
            <>
              <Button small type="secondary" className="close-button" onClick={handleClose}>
                {getText('close')}
              </Button>
              <Button small onClick={onSubmit}>
                {getText('addMembers')}
              </Button>
            </>
          )}
        </div>
      </Form>
    </Modal>
  );
};

AddMembersModal.propTypes = {
  showModal: PropTypes.bool,
  onAdd: PropTypes.func,
  onClose: PropTypes.func,
};

export default AddMembersModal;
