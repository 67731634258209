import { capitalize, isArray, isPlainObject } from 'lodash-es';
import { useTranslations } from '@veraio/strank';
import { loginWithPassword } from '@oneecosystem/authenticate';
import { Button, Col, Form, Input, inputValidation, Row, showError } from 'components';
import { ReactComponent as Logo } from 'assets/images/logo.svg';
import { publicContentContainer } from './styles';
// import { setupNotifications } from 'messaging';

const Login = ({ history }) => {
  const { getText } = useTranslations();

  const handleLogin = async (data, resetForm) => {
    const res = await loginWithPassword(data);
    const err =
      // Error from our api
      res?.errorDescription ||
      // Keycloak error
      (isArray(res) && res[0]?.message && 'Incorrect username or password') ||
      // Error from sts identity
      (isPlainObject(res?.errors) && Object.values(res.errors).map((el) => el[0]));

    resetForm(err);

    //     Error from sts identity                  Default behavior
    if (err) return isArray(err) ? err.forEach(showError) : showError(err);

    return [res, err];
  };

  return (
    <Row css={publicContentContainer}>
      <Col sm={12} md={6} lg={7} className="action-column">
        <Logo onClick={() => history.push('/')} className="logo" />

        <Form onSubmit={handleLogin} className="form-container">
          <h1 className="title">{getText('logIn')}</h1>
          <Input
            required
            formId="email"
            margin="0 0 1em"
            label={getText('emailAddress')}
            validate={inputValidation('required', 'email')}
          />
          <Input
            required
            type="password"
            formId="password"
            margin="0 0 1em"
            label={getText('password')}
            validate={inputValidation('required', 'password')}
          />

          {/* <Button type="link" linkTo={ROUTES.ForgotPassword} className="forgot-button">
            {getText('forgotPassword')}
          </Button> */}

          <div className="action-container">
            {({ onSubmit }) => (
              <Button small onClick={onSubmit} className="login-button">
                {getText('logIn')}
              </Button>
            )}
          </div>
        </Form>
        <div>
          <p className="paragraph">
            {`${getText('byContinuingYouAgree')} `}
            <span className="underline-text">{getText('termsOfService')}</span> {getText('and')}{' '}
            <span className="underline-text">{capitalize(getText('privacyPolicy'))}.</span>
          </p>
        </div>
      </Col>
      <Col sm={12} md={6} lg={5} className="pictureContainer" />
    </Row>
  );
};

export default Login;
