import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslations } from '@veraio/strank';
import { hasAtLeastOneRole, hasRole, USER_ROLES } from '@oneecosystem/authenticate';
import { setCurrentChat, useChatStore, resetUnreadMessages, getUserId } from 'stores';
import { createDirectChat, removeUsersFromChannel, removeUsersFromGroup } from 'services';
import { ActionsContainer, Avatar, Collapse, ConfirmationModal, Icon } from 'components';
import { userChatStatusMap, chatTypes } from 'enums';
import { chatInfo, userInfo } from './config';
import { chatInfoContainer } from './styles';
import { getFirstSymbol, getUserDisplayName } from 'utils';

const restrictedChannels = ['General', 'Support', 'KYC', 'Compliance'];

const ChatInfo = (props) => {
  const { onClose } = props;
  const { getText } = useTranslations();
  const { currentChat, chats } = useChatStore();
  const userId = getUserId();
  const [penpal, setPenpal] = useState({});
  const [openContainer, setOpenContainer] = useState({ fileActions: false, memberActions: false });
  const [openModal, setOpenModal] = useState({ removeMember: false, user: null });
  const isModeratorAgent = hasRole(USER_ROLES.CHAT_MODERATOR);
  const isAdmin = hasAtLeastOneRole([USER_ROLES.CHAT_ADMIN, USER_ROLES.CHAT_SUPER_ADMIN]);

  useEffect(() => {
    // Set the other chat member if it is a direct chat.
    currentChat?.targetType === chatTypes.chat &&
      setPenpal(currentChat?.users?.find((user) => user.id !== userId) ?? {});
  }, [currentChat]);

  const hideContainer = () => setOpenContainer({ fileActions: false, memberActions: false });

  const toggleMemberActionsContainer = (id) => {
    setOpenContainer((prev) => ({
      ...prev,
      memberActions: !prev.memberActions,
      openElement: !prev.memberActions && id,
    }));
  };

  const handleOpenChatWithUser = (id) => {
    const foundChat = chats?.find((chat) => chat.users.find((user) => user.id === id));

    if (!foundChat) {
      setCurrentChat(id, chatTypes.chat);
      createDirectChat({ user: id });
    } else {
      setCurrentChat(foundChat.id, chatTypes.chat);
      resetUnreadMessages(foundChat.id);
    }

    setOpenContainer({ fileActions: false, memberActions: false });
    onClose();
  };

  const handleOpenRemoveMemberModal = (userData) => {
    setOpenModal({ removeMember: true, user: userData });
  };

  const handleCloseModal = () => {
    setOpenModal({});
  };

  const handleRemoveMemberFromChat = () => {
    const data = { id: currentChat.id, users: [openModal.user.id] };

    if (currentChat?.targetType === chatTypes.group) removeUsersFromGroup(data, handleCloseModal);
    else if (currentChat?.targetType === chatTypes.channel) removeUsersFromChannel(data, handleCloseModal);
  };

  const checkCanRemoveMember = (member) => {
    if (currentChat.owner.id === member.id) return false;
    if (isAdmin) return true;

    if (isModeratorAgent) {
      return !member.roles.find(
        (role) =>
          role === USER_ROLES.CHAT_SUPER_ADMIN || role === USER_ROLES.CHAT_ADMIN || role === USER_ROLES.CHAT_MODERATOR,
      );
    }
  };

  return (
    <div css={chatInfoContainer(penpal && userChatStatusMap[penpal.status]?.color)}>
      <div className="page-title">
        <Icon iconName="close" color="secondary" onClick={onClose} />
      </div>

      <div className="user-container">
        {currentChat?.targetType === chatTypes.group ||
        (currentChat?.targetType === chatTypes.channel && !currentChat?.icon) ? (
          <div className="letter-avatar">
            <div>{getFirstSymbol(currentChat?.name, currentChat?.targetType[0])}</div>
          </div>
        ) : currentChat?.targetType === chatTypes.channel ? (
          <Avatar border width={96} picThumbnailUrl={currentChat?.icon} />
        ) : (
          <Avatar border width={96} picThumbnailUrl={penpal?.pictureUrl} status={penpal?.status} />
        )}
        <h5 className="name">{currentChat?.name}</h5>
        <div className="status-container">
          <div className="status-indicator" />
          {penpal?.status && <p className="status">{penpal.status}</p>}
        </div>
      </div>

      <div className="profile-description">
        {currentChat?.description && <p className="motto">{currentChat.description}</p>}

        <Collapse header={getText('about')} cardIconName="person">
          <div className="user-content-container">
            {(currentChat?.targetType === chatTypes.chat ? userInfo(penpal) : chatInfo(currentChat))?.map((el, ind) => (
              <div key={ind} className="user-content">
                <p className="label">{getText(el.label)}</p>
                <h5 className="name">{el.value ?? '-'}</h5>
              </div>
            ))}
          </div>
        </Collapse>

        {currentChat && currentChat.targetType !== chatTypes.chat && (
          <>
            <Collapse
              disabled={currentChat.targetType === chatTypes.channel && restrictedChannels.includes(currentChat.name)}
              header={`${getText('members')} (${currentChat?.users?.length ?? 0})`}
              cardIconName="group">
              <div className="members-container">
                {currentChat?.users?.map((member, ind) => (
                  <div key={ind} className="member-content">
                    <div className="member-info-container">
                      <div className="member-image-container">
                        <Avatar pictureUrl={member?.pictureUrl} />
                      </div>
                      <div>
                        <h5 className="name">{getUserDisplayName(member)}</h5>
                        {member?.role && <span>({member.role})</span>}
                      </div>
                    </div>
                    {member?.id !== userId && (
                      <div>
                        <Icon
                          iconName="more_horiz"
                          color="secondary"
                          size={24}
                          onClick={() => toggleMemberActionsContainer(member.id)}
                        />
                        {openContainer.memberActions && openContainer.openElement === member.id && (
                          <ActionsContainer
                            styles={{ top: 36, right: 10, width: 170, background: 'background-stronger' }}
                            onBlur={hideContainer}>
                            <div
                              role="button"
                              tabIndex={0}
                              onClick={() => handleOpenChatWithUser(member.id)}
                              className="item">
                              <p className="name">{getText('message')}</p>
                            </div>
                            {currentChat.targetType === chatTypes.channel && checkCanRemoveMember(member) && (
                              <>
                                <hr className="horizontal-line" />
                                <div
                                  role="button"
                                  tabIndex={0}
                                  onClick={() => handleOpenRemoveMemberModal(member)}
                                  className="item">
                                  <p className="name">{getText('remove')}</p>
                                </div>
                              </>
                            )}
                          </ActionsContainer>
                        )}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </Collapse>

            {(isModeratorAgent || isAdmin) && (
              <ConfirmationModal
                showModal={openModal.removeMember}
                onOk={handleRemoveMemberFromChat}
                onCancel={handleCloseModal}
                title={getText('removeMember')}>
                {getText('confirmRemoveMember', { member: `<b>${getUserDisplayName(openModal.user ?? {})}</b>` })}
              </ConfirmationModal>
            )}
          </>
        )}
      </div>
    </div>
  );
};

ChatInfo.propTypes = {
  onClose: PropTypes.func,
};

export default ChatInfo;
