export const orderUsersByLetter = (users) => {
  if (!users) return [];
  const orderedUsers = {};

  users?.forEach((user) => {
    const firstLetter = (user.firstName || user.lastName || user.username)[0]?.toUpperCase() ?? 'Other';

    // if other than AE symbol to others?

    if (orderedUsers[firstLetter]) {
      orderedUsers[firstLetter] = [...orderedUsers[firstLetter], user].sort((a, b) => {
        if (a.firstName > b.firstName) return 1;
        if (a.firstName < b.firstName) return -1;

        if (a.lastName > b.lastName) return 1;
        if (a.lastName < b.lastName) return -1;

        return 0;
      });
    } else orderedUsers[firstLetter] = [user];
  });

  return orderedUsers;
};

export const getUserDisplayName = (userData) => {
  let displayName = userData?.firstName;

  if (userData?.lastName) displayName = `${displayName && displayName + ' '}${userData.lastName}`;
  return displayName || userData?.username;
};
