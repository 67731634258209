import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { hasAtLeastOneRole, hasRole, USER_ROLES } from '@oneecosystem/authenticate';
import { useTranslations } from '@veraio/strank';
import { addOldMessages, getUserId, resetUnreadMessages, useChatStore } from 'stores';
import { displayDate, getDateTimeLabel, getTimestampFromUuidv7, getUserDisplayName, parseUuid7ToDate } from 'utils';
import { Avatar } from 'components/shared';
import { Icon, Spinner, useIntersectionObserver } from 'components/ui';
import MessageBox from '../MessageBox';
import { chatBodyContainer, messageContainer } from './styles';
import { getPreviousMessages } from 'services';
import { debounce } from 'lodash-es';
import { uuidv7 } from 'uuidv7';

const PAGE_SIZE = 20;

const ConversationContainer = (props) => {
  // The data prop includes only messages
  const { chatMessages, unreadCount } = props;
  const isAgentMod = hasRole(USER_ROLES.CHAT_MODERATOR);
  const isAdmin = hasAtLeastOneRole([USER_ROLES.CHAT_SUPER_ADMIN, USER_ROLES.CHAT_ADMIN]);
  const { getText } = useTranslations();
  const { currentChat } = useChatStore();
  const userId = getUserId();

  const [loading, setLoading] = useState(false);
  const [hasMoreMessages, setHasMoreMessages] = useState(true);
  const [newMessageAdded, setNewMessageAdded] = useState(false);
  const [messages, setMessages] = useState(null);
  const [pageIndex, setPageIndex] = useState(1);
  const [showMessages, setShowMessages] = useState([]);

  // Refs to watch for scroll
  const chatContainer = useRef();
  const sentinelRef = useRef();

  // change current chat - OK
  useEffect(() => {
    // Clear messages, reset hasMore and paging, and scroll to the last sent message as we have opened new chat
    pageIndex !== 1 && setPageIndex(1);
    setMessages(null);
    setHasMoreMessages(true);
    chatContainer.current.scrollTop = 0;
  }, [currentChat?.id]);

  useEffect(() => {
    chatMessages?.length ? updateMessages() : fetchMessagesHistory(uuidv7());
  }, [chatMessages]);

  useEffect(() => {
    if (chatMessages && messages) {
      // If there are more than 20 messages to get from the store, get them from the store
      if (chatMessages.length - messages.length >= PAGE_SIZE) return getMessagesFromStore({ pageIndex });

      // If we don't have enough messages to get from the store, fetch them from BE
      const lastMessage = messages.slice(-1)[0];
      lastMessage && fetchMessagesHistory(lastMessage.messageId);
    }
  }, [pageIndex]);

  useEffect(() => {
    if (newMessageAdded) {
      const { scrollTop } = chatContainer.current;
      if (scrollTop > -250 || unreadCount === 0) handleShowNewMessages();
      setNewMessageAdded(false);
    }
  }, [newMessageAdded]);

  useIntersectionObserver(
    sentinelRef,
    chatContainer,
    (entry) => entry?.isIntersecting && hasMoreMessages && !loading && intersectionObserverHandler(),
    { threshold: [0.1] },
  );

  const intersectionObserverHandler = () => {
    // If there are no messages in the state, get messages from the store.
    // There is at least one message in the store
    // Use the default pageSize
    if (!messages?.length) {
      setPageIndex(1);
      return getMessagesFromStore({ pageIndex: 1 });
    }

    // Change pageSize
    setPageIndex((prev) => prev + 1);
  };

  const updateMessages = () => {
    setLoading(true);
    if (!messages) {
      // First opened or changed chat - messages will be null. Get messages from store.
      pageIndex !== 1 && setPageIndex(1);
      getMessagesFromStore({ pageIndex: 1 });

      // If messages in store are less than PAGE_SIZE, fetch from BE more messages.
      chatMessages?.length < PAGE_SIZE && fetchMessagesHistory(chatMessages.slice(-1)[0]?.messageId);
    } else if ((messages ?? [])[0]?.messageId === (chatMessages ?? [])[0]?.messageId) {
      // Old messages were added or some messages were edited/deleted
      // (!!!) If messages were edited or deleted - should refresh all messages

      // If there are less than 20 messages to get from the store, should fetch from BE
      const shouldFetch = chatMessages.length - messages.length < PAGE_SIZE;
      getMessagesFromStore({ pageIndex });

      // If we don't have enough messages to get from the store, fetch them from BE
      shouldFetch && hasMoreMessages && fetchMessagesHistory(messages.slice(-1)[0].messageId);
    } else {
      // New messages have been added to the store
      if (hasMoreMessages) {
        // There are more messages to get from the store, so we need to slice only the messages that were rendered plus the new ones
        const lastIndex = chatMessages?.findIndex((msg) => msg.messageId === messages.slice(-1)[0]?.messageId);
        const lastToGet = lastIndex >= 0 ? lastIndex + 1 : PAGE_SIZE * pageIndex + unreadCount;
        const slicedData = chatMessages?.slice(0, lastToGet) ?? [];
        setMessages(slicedData);
      } else {
        setMessages(chatMessages ?? []);
        setPageIndex(chatMessages ? Math.ceil(chatMessages.length / PAGE_SIZE) : 1);
      }

      setNewMessageAdded(true);
      setLoading(false);
    }
  };

  const checkForNewDayTitle = (currentMessageId, prevMessageId) => {
    if (currentMessageId === prevMessageId) return true;

    const currentDate = parseUuid7ToDate(currentMessageId);
    const lastMessageDate = parseUuid7ToDate(prevMessageId);
    return (
      currentDate.getDate() !== lastMessageDate.getDate() ||
      currentDate.getMonth() !== lastMessageDate.getMonth() ||
      currentDate.getFullYear() !== lastMessageDate.getFullYear()
    );
  };

  const handleShowNewMessages = () => {
    chatContainer.current.scrollTo({ top: 0, behavior: 'smooth' });
    currentChat?.id && resetUnreadMessages(currentChat.id);
  };

  const handleUserScroll = debounce((e) => {
    if (loading) return;

    const { scrollTop } = e.target;
    // console.log(scrollTop, scrollHeight, clientHeight);

    if (scrollTop >= -50) currentChat?.id && resetUnreadMessages(currentChat.id);
  }, 200);

  const fetchMessagesHistory = (lastMessageId) => {
    //If we don't have message id we can generate UUID which timestamp will be now and we will get messages before now
    if (!lastMessageId || !hasMoreMessages || loading) return;

    const req = {
      targetType: currentChat.targetType,
      targetId: currentChat.id,
      lastId: lastMessageId,
      count: PAGE_SIZE,
    };

    getPreviousMessages(req, (payload) => {
      // If payload has less then 20 messages, we don't have more messages to fetch
      payload?.length < PAGE_SIZE && setHasMoreMessages(false);

      if (payload?.length === 0) return setLoading(false);

      // The payload contains the history messages. Updating the store will affect the messages
      // Loading state will be updated after we add these messages to the state
      const mappedMessages = payload.map((msg) => ({ ...msg, targetType: req.targetType, targetId: req.targetId }));
      addOldMessages(mappedMessages, req.targetId);
      // setMessages((prev) => [...prev, ...mappedMessages]);
      setLoading(false);
    });
  };

  const getMessagesFromStore = (options) => {
    if (!currentChat || !chatMessages) {
      setMessages([]);
      return setHasMoreMessages(false);
    }

    // Save current scroll position before loading new messages
    const previousScrollHeight = chatContainer.current.scrollHeight;

    // Get next messages from store
    const messagesSlice = chatMessages?.slice(0, PAGE_SIZE * options.pageIndex);
    setMessages(messagesSlice ?? []);

    // Calculate the difference in scroll height after loading new messages
    const newScrollHeight = chatContainer.current.scrollHeight;
    const scrollDiff = newScrollHeight - previousScrollHeight;

    // Adjust the scrollTop to maintain the user's position after loading messages
    chatContainer.current.scrollTop += scrollDiff;
    setLoading(false);
  };

  const checkForSenderData = (currentMessage, nextMessage) => {
    if (checkForNewDayTitle(nextMessage.messageId, currentMessage.messageId)) return true;
    if (!nextMessage || currentMessage?.author?.id !== nextMessage?.author?.id) return true;
    if (nextMessage?.deletedAt) return true;

    return false;
  };

  return (
    <div ref={chatContainer} css={chatBodyContainer} onScroll={handleUserScroll}>
      {!!unreadCount && (
        <div className="new-messages">
          <div role="button" tabIndex={0} className="new-messages-button" onClick={handleShowNewMessages}>
            {getText('newMessages')}
            <Icon iconName="keyboard_arrow_down" />
          </div>
        </div>
      )}
      {messages?.length ? (
        messages.map((msg, index) => (
          <div key={`message-${msg.messageId}-${index}`}>
            {checkForNewDayTitle(
              msg.messageId,
              messages[index !== messages.length - 1 ? index + 1 : index]?.messageId,
            ) && (
              <div className="chat-day-title">
                <span className="title">{getDateTimeLabel(msg.messageId, true)}</span>
              </div>
            )}
            <div css={messageContainer(msg.author.id === userId)}>
              {!msg.deletedAt || showMessages.includes(msg.messageId) ? (
                <>
                  <div className="avatar-column">
                    {checkForSenderData(msg, messages[index > 0 ? index - 1 : 0]) && (
                      <Avatar picThumbnailUrl={msg.author.pictureUrl} />
                    )}
                  </div>
                  <div className="message-column">
                    <MessageBox
                      message={msg}
                      isOwn={msg.author.id === userId}
                      onHide={() => setShowMessages((prev) => prev.filter((id) => id !== msg.messageId))}
                    />
                    <div className="message-box-item" />
                    <p className="sender-name">
                      {checkForSenderData(msg, messages[index > 0 ? index - 1 : 0]) && getUserDisplayName(msg.author)}
                    </p>
                  </div>
                </>
              ) : (
                isAdmin && (
                  <button
                    className="deleted-message"
                    onClick={() => setShowMessages((prev) => [...prev, msg.messageId])}>
                    {getText('messageWasDeleted')}
                  </button>
                )
              )}
            </div>
          </div>
        ))
      ) : (
        <div className="empty-chat-container">
          <p className="no-messages-text">{getText('noMessages')}</p>
          {(isAgentMod || isAdmin || !currentChat?.readOnly) && (
            <p className="start-chat-text">{getText('beTheFirstToSayHi', { emoji: '👋' })}</p>
          )}
        </div>
      )}
      {/* <span>{!hasMoreMessages && getText('noMoreMessages')}</span> */}
      <div ref={sentinelRef} className="load-more-container" style={{ height: '10px', display: 'block' }}>
        <Spinner size={PAGE_SIZE} loading={loading} />
      </div>
    </div>
  );
};

ConversationContainer.propTypes = {
  chatMessages: PropTypes.array,
  unreadCount: PropTypes.number,
};

export default ConversationContainer;
