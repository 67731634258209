import moment from 'moment';

moment.updateLocale('en', {
  relativeTime: {
    s: 'seconds',
    ss: '%ss',
    m: 'a minute',
    mm: '%dm',
    h: 'an hour',
    hh: '%dh',
    d: 'a day',
    dd: '%dd',
    M: 'a month',
    MM: '%d months',
    y: 'a year',
    yy: '%d years',
  },
});

export const defaultDate = '0001-01-01T00:00:00';

export const validDate = (date) => (date === defaultDate ? null : date);

export const endOfTheDay = (inputDate) => inputDate && moment(inputDate).hours(23).minutes(59).seconds(59);

export const startOfTheDay = (inputDate) => inputDate && moment(inputDate).hours('00').minutes('00').seconds('00');

export const convertUTCDateToLocalDate = (date) => {
  let temp = date;
  temp = temp?.replace(/Z$/, '');
  if (temp) {
    const tempDate = new Date(temp);
    const newDate = new Date(tempDate.getTime() + tempDate.getTimezoneOffset() * 60 * 1000);
    const offset = tempDate.getTimezoneOffset() / 60;
    const hours = tempDate.getHours();
    newDate.setHours(hours - offset);
    return newDate;
  }
};

export const getTimeFromNow = (inputDate, withoutSuffix = false) =>
  moment(convertUTCDateToLocalDate(inputDate)).fromNow(withoutSuffix);

export const displayTime = (date, format = 'hh:mm A') => validDate(date) && moment(date).format(format);
export const displayDate = (date, format = 'MMM DD, hh:mm A') => validDate(date) && moment(date).format(format);

export const parseUuid7ToDate = (uuid) => {
  const timestamp = getTimestampFromUuidv7(uuid);
  const resultDate = new Date(timestamp);

  return resultDate;
};

export const getTimestampFromUuidv7 = (uuid) => {
  const timestampHex = uuid?.slice(0, 13).replace('-', '');
  return Number.parseInt(timestampHex, 16);
};

export const getDateTimeLabel = (uuid, todayText) => {
  const date = parseUuid7ToDate(uuid);
  const now = new Date();

  const diff = now - date;
  const oneDay = 24 * 60 * 60 * 1000;

  // Check if the date is today
  // if (diff < oneDay && date.getDate() === now.getDate()) return displayTime(date);
  if (diff < oneDay && date.getDate() === now.getDate()) return todayText ? 'Today' : displayTime(date);

  // Check ig the date is within the last week
  if (diff < 7 * oneDay) {
    const daysOfWeek = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
    return `${daysOfWeek[date.getDay()]} ${displayTime(date)}`;
  }

  // If the date is not within the last week, show the full date
  return displayDate(date);
};

export const orderByTimestamp = (uuidA, uuidB, asc) => {
  const timeA = uuidA ? getTimestampFromUuidv7(uuidA) : 0;
  const timeB = uuidB ? getTimestampFromUuidv7(uuidB) : 0;

  return asc ? timeA - timeB : timeB - timeA;
};