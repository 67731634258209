import { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslations } from '@veraio/strank';
import { hasRole, USER_ROLES } from '@oneecosystem/authenticate';
import { getUserId } from 'stores';
import { chatTypes } from 'enums';
import { Icon, SearchBar } from 'components/ui';
import { ActionsContainer, ConfirmationModal } from 'components/shared';
import { ChannelFormModal } from 'components/screens';
import {
  addUsersToChannel,
  addUsersToGroup,
  deleteChannel,
  deleteGroup,
  joinChannel,
  leaveChannel,
  leaveGroup,
  updateChannel,
} from 'services';
import AddMembersModal from '../AddMembersModal';
import { chatActionsContainer } from './styles';
import { actionsConfig } from './config';

const chatInfoIcon = {
  [chatTypes.user]: 'person',
  [chatTypes.group]: 'group',
  [chatTypes.channel]: 'groups',
};

const actionTextKey = {
  [chatTypes.group]: {
    delete: 'deleteGroup',
    confirmDelete: 'confirmDeleteGroup',
    leave: 'leaveGroup',
    confirmLeave: 'confirmLeaveGroup',
  },
  [chatTypes.channel]: {
    delete: 'deleteChannel',
    confirmDelete: 'confirmDeleteChannel',
    leave: 'leaveChannel',
    confirmLeave: 'confirmLeaveChannel',
    join: 'joinChannel',
    confirmJoin: 'confirmJoinChannel',
  },
};

const ChatActions = (props) => {
  const { chat, onOpenChatInfo, onSearch } = props;
  const userId = getUserId();
  const { getText } = useTranslations();
  const searchRef = useRef();
  const isModerator = hasRole(USER_ROLES.CHAT_MODERATOR);
  const isAdmin = hasRole(USER_ROLES.CHAT_ADMIN);
  const isSuperAdmin = hasRole(USER_ROLES.CHAT_SUPER_ADMIN);

  const [openContainer, setOpenContainer] = useState({ chatActions: false, search: false });
  const [openModal, setOpenModal] = useState({
    addMembers: false,
    delete: false,
    edit: false,
    leave: false,
    join: false,
  });

  const actions = actionsConfig({
    isOwner: chat?.owner?.id === userId,
    isAdmin,
    isSuperAdmin,
    isMember: chat?.users?.find((user) => user.id === userId),
  });

  const toggleSearch = () => setOpenContainer((prev) => ({ search: !prev.search }));

  const openAddMembersModal = (e) => {
    e.stopPropagation();
    setOpenModal({ addMembers: true });
  };

  const handleOpenModal = (e, key) => {
    e.stopPropagation();
    setOpenModal({ [key]: true });
  };

  const handleCloseModal = () => setOpenModal({});

  const handleAddMembersToChat = (users, resetForm) => {
    if (chat?.targetType === chatTypes.group) {
      addUsersToGroup({ id: chat?.id, users }, (response) => {
        resetForm(!response.success);
        handleCloseModal();
      });
    } else if (chat?.targetType === chatTypes.channel) {
      addUsersToChannel({ id: chat?.id, users }, (response) => {
        resetForm(!response.success);
        handleCloseModal();
      });
    }
  };

  const handleEditChat = (chatData, resetForm) => {
    updateChannel(chatData, (response) => {
      resetForm(!response.success);
      handleCloseModal();
    });
  };

  const handleJoinChannel = () => joinChannel(chat?.id, handleCloseModal);

  const handleLeaveChat = () => {
    if (chat?.targetType === chatTypes.group) leaveGroup(chat?.id, handleCloseModal);
    else if (chat?.targetType === chatTypes.channel) leaveChannel(chat?.id, handleCloseModal);
  };

  const handleDeleteChat = () => {
    if (chat?.targetType === chatTypes.group) deleteGroup(chat?.id, handleCloseModal);
    else if (chat?.targetType === chatTypes.channel) deleteChannel(chat.id, handleCloseModal);
  };

  const toggleMoreContainer = (e) => {
    e.stopPropagation();
    setOpenContainer((prev) => ({ chatActions: !prev.chatActions }));
  };

  const handleCloseContainer = () => setOpenContainer({});

  return (
    <div css={chatActionsContainer}>
      <div className="chat-actions-container">
        {(isAdmin ||
          isSuperAdmin ||
          chat?.targetType === chatTypes.chat ||
          chat?.users?.find((member) => member.id === userId)) && (
          <>
            <div className="search-container">
              <Icon iconName="search" className="action-icon" onClick={toggleSearch} />
              {openContainer.search && (
                <SearchBar
                  inputRef={searchRef}
                  className="search-bar"
                  onChange={onSearch}
                  // options={[]}
                />
              )}
            </div>
            {((chat?.targetType === chatTypes.group && chat?.owner?.id === userId) ||
              (chat?.targetType === chatTypes.channel && (isModerator || isAdmin || isSuperAdmin))) && (
              <Icon iconName="person_add" className="action-icon" onClick={openAddMembersModal} />
            )}
          </>
        )}
        <Icon iconName={chatInfoIcon[chat?.targetType] ?? 'person'} className="action-icon" onClick={onOpenChatInfo} />
        {!!actions[chat?.targetType]?.length && (
          <Icon iconName="more_horiz" className="action-icon" onClick={toggleMoreContainer} />
        )}
        {openContainer.chatActions && (
          <ActionsContainer styles={{ top: 60, right: 20, width: 150 }} onBlur={handleCloseContainer}>
            {actions[chat?.targetType]?.map((config) => (
              <div
                key={config.key}
                role="button"
                tabIndex={0}
                onClick={(event) => handleOpenModal(event, config.key)}
                className="item">
                <p>{config.label}</p>
                <Icon iconName={config.icon} size={16} />
              </div>
            ))}
          </ActionsContainer>
        )}
      </div>
      <div>
        {(isModerator || isAdmin || isSuperAdmin || chat?.owner?.id === userId) && (
          <AddMembersModal showModal={openModal.addMembers} onAdd={handleAddMembersToChat} onClose={handleCloseModal} />
        )}
        {(isAdmin || isSuperAdmin || chat?.owner?.id === userId) && (
          <>
            <ChannelFormModal
              data={chat}
              showModal={openModal.edit}
              onSubmit={handleEditChat}
              onClose={handleCloseModal}
            />
            <ConfirmationModal
              showModal={openModal.delete}
              title={getText(actionTextKey[chat?.targetType]?.delete)}
              onOk={handleDeleteChat}
              onCancel={handleCloseModal}>
              {getText(actionTextKey[chat?.targetType]?.confirmDelete)}
            </ConfirmationModal>
          </>
        )}
        <ConfirmationModal
          showModal={openModal.leave}
          title={getText(actionTextKey[chat?.targetType]?.leave)}
          onOk={handleLeaveChat}
          onCancel={handleCloseModal}>
          {getText(actionTextKey[chat?.targetType]?.confirmLeave)}
        </ConfirmationModal>
        <ConfirmationModal
          showModal={openModal.join}
          title={getText(actionTextKey[chat?.targetType]?.join)}
          onOk={handleJoinChannel}
          onCancel={handleCloseModal}>
          {getText(actionTextKey[chat?.targetType]?.confirmJoin)}
        </ConfirmationModal>
      </div>
    </div>
  );
};

ChatActions.propTypes = {
  chat: PropTypes.object,
  onOpenChatInfo: PropTypes.func,
  onSearch: PropTypes.func,
};

export default ChatActions;
