export const chatContainer = (styles, isOpen) => [
  {
    width: '100%',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '100vh',
    maxWidth: 'calc(100vw - 457px)',
    boxShadow: '0 2px 4px rgba(15,34,58,.12)',
    position: 'relative',
    backgroundColor: 'var(--theme-color)',

    '.no-chat-container': {
      margin: 'auto',
      textAlign: 'center',
      color: 'var(--secondary-weaker)',

      p: {
        '&.welcome-text': {
          fontSize: 36,
          margin: 0,
        },
        '&.secondary-text': {
          fontSize: 18,
          opacity: 0.8,
          margin: '2rem 8rem',
        }
      },

      '.no-chat-icon': {
        fontSize: 100,
      },
    },

    '.restricted-chat-footer': {
      minHeight: 90,
      fontSize: 16,
      fontWeight: 600,
      textAlign: 'center',
      padding: '2rem 8rem',
      color: 'var(--secondary)',
      borderTop: '1px solid var(--border-color)'
    },
  },
  styles,
  !isOpen && {
    '@media (max-width: 991px)': {
      display: 'none',
    },
  },
];

export const chatHeaderContainer = (statusColor) => [
  {
    height: 100,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '1.5rem',
    borderBottom: '1px solid var(--border-color)',

    '.chat-title': {
      display: 'flex',
      alignItems: 'center',
      maxWidth: '65%',

      '.go-back-icon': {
        display: 'none',
        marginRight: 10,
        color: 'var(--secondary)',

        '&:hover': {
          color: 'var(--gray-200)',
        },

        '@media (max-width: 991px)': {
          display: 'block',
        },
      },

      '.letter-avatar': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'var(--primary-weaker)',
        color: 'var(--primary)',
        borderRadius: '50%',
        width: '36px',
        minWidth: '36px',
        height: '36px',
        padding: 5,
        fontWeight: 500,
      },

      '.chat-name': {
        marginLeft: 15,
        fontSize: 16,
        fontWeight: 500,
        cursor: 'pointer',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    },
  },
  statusColor && {
    '.chat-title': {
      '.status-indicator': {
        width: 8,
        height: 8,
        backgroundColor: 'var(--theme-color)',
        border: `3px solid var(--${statusColor})`,
        borderRadius: '50%',
        marginLeft: 5,
      },

      '.last-active-time': {
        color: 'var(--secondary)',
        fontSize: 12,
        marginLeft: 4,
      },
    },
  },
];

export const chatBodyContainer = {
  flexBasis: '100%',
  padding: '1.5rem',
  overflow: 'auto',

  '.chat-day-title': {
    marginBottom: 24,
    marginTop: 12,
    position: 'relative',
    textAlign: 'center',
    '.title': {
      backgroundColor: 'var(--background)',
      padding: '0.5rem 1.25rem',
      borderRadius: '5px',
      position: 'relative',
      zIndex: 9,
    },
    '::before': {
      content: '""',
      height: '1px',
      backgroundColor: 'var(--border-color-weak)',
      position: 'absolute',
      right: 0,
      top: '10px',
      width: '100%',
    },
  },

  '.empty-chat-container': {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'center',
    color: 'var(--secondary)',
    '.no-messages-text': {
      fontWeight: 600,
    },
    '.start-chat-text': {
      fontSize: 13,
    },
  },
};

export const messageContainer = (isRight) => [
  {
    display: 'flex',
    alignItems: 'flex-end',
    flexDirection: isRight ? 'row-reverse' : 'row',
    '.avatar-column': {
      minWidth: 50,
      maxWidth: 50,
      marginBottom: 0,
      textAlign: 'center',
    },
    '.message-column': {
      textAlign: isRight ? 'right' : 'left',
      position: 'relative',
      margin: 'auto 10px',

      '.message-box-item': {
        height: 0,
        width: 0,
        position: 'absolute',
        right: isRight && 0,
        borderStyle: 'solid',
        borderWidth: isRight ? '0 14px 14px 0' : '14px 14px 0 0',
        borderColor: isRight
        ? 'transparent var(--background-weak) transparent transparent'
        : 'var(--primary) transparent transparent transparent',
        transform: 'rotate(0deg)',
      },
      '.sender-name': {
        marginTop: 15,
        fontWeight: 500,
      },
    },
  },
];
